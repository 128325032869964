<template>
  <div>
    <location />
    <div class="page wrap-1200 rule">
      <div class="rule-overview">
        <h5 class="mb-3">{{ $t("text.main.term_title_00") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_title_001") }}
          </li>
          <li>
            {{ $t("text.main.term_title_002") }}
          </li>
        </ul>
      </div>

      <div class="rule-list">
        <h5>{{ $t("text.main.term_title_01") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_010") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_02") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_021") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_022") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_023") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_024") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_025") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_026") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_027") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_028") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_029") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_03") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_031") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_032") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_033") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_034") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_035") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_04") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_0401") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_0402") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_0403") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_05") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_051") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_052") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_053") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_06") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_061") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_062") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_063") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_064") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_065") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_07") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_071") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_072") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_0721") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0722") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0723") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0724") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0725") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0726") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0727") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0728") }}
              </li>
            </ul>
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_08") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_081") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_082") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_083") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_084") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_0841") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0842") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0843") }}
              </li>
            </ul>
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_09") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_091") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_092") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_093") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_094") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_0941") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0942") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0943") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0944") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_095") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_096") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_0961") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0962") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0963") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0964") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_0965") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_097") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_098") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_100") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_101") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_102") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_103") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_104") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_110") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_111") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1111") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_112") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1121") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_113") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1131") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_114") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1141") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_115") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1151") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_116") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1161") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1162") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1163") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1164") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1165") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1166") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_117") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_118") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_120") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_121") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_122") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_123") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_124") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_125") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_126") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_130") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_131") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1311") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1312") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1313") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1314") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1315") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1316") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_132") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_133") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_140") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_141") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_142") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1421") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1422") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_143") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_144") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_145") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_146") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_150") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_151") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_152") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_153") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_160") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_161") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_162") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_170") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_171") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_172") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_180") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_181") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.term_sub_title_1811") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1812") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1813") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1814") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1815") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1816") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1817") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1818") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_1819") }}
              </li>
              <li>
                {{ $t("text.main.term_sub_title_18110") }}
              </li>
            </ul>
          </li>
          <li>
            {{ $t("text.main.term_sub_title_182") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_190") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_191") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_192") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_200") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_201") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_202") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_203") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_204") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_205") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_206") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_207") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_208") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_209") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_2010") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_210") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_211") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_212") }}
          </li>
          <li>
            {{ $t("text.main.term_sub_title_213") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.term_title_220") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.term_sub_title_220") }}
          </li>
        </ul>
      </div>
      <!-- // rule-list -->
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";

export default {
  name: "Terms",
  components: {
    location,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>