<template>
  <div>
    <location />
    <div class="page wrap-1200 rule">
      <div class="rule-overview">
        {{ $t("text.main.privacy_title_00") }}
      </div>

      <div class="rule-list">
        <h5>{{ $t("text.main.privacy_title_01") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_011") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.privacy_sub_title_0111") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0112") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0113") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0114") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0115") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0116") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0117") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0118") }}
              </li>                                                       
            </ul>            
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_012") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_013") }}
          </li>
        </ul>

        <h5>{{ $t("text.main.privacy_title_02") }}</h5>
        <ul>
          <li>
            <ul class="sub-item">
              <li>
                {{ $t("text.main.privacy_sub_title_0201") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0202") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0203") }}<br>
                {{ $t("text.main.privacy_sub_title_02031") }}
              </li>
            </ul>            
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_021") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.privacy_sub_title_0211") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0212") }}
              </li>
            </ul>            
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_022") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.privacy_sub_title_0221") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0222") }}
              </li>
            </ul>            
          </li>
        </ul>

        <h5>{{ $t("text.main.privacy_title_03") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_031") }}
            <ul class="sub-item">
              <li>
                {{ $t("text.main.privacy_sub_title_0311") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0312") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0313") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0314") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0315") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0316") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0317") }}
              </li>
              <li>
                {{ $t("text.main.privacy_sub_title_0318") }}
              </li>
            </ul>            
          </li>
        </ul>              

        <h5>{{ $t("text.main.privacy_title_04") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_041") }}
          </li> 
          <li>
            {{ $t("text.main.privacy_sub_title_042") }}
            <ul class="sub-item">
              <li>{{ $t("text.main.privacy_sub_title_0421") }}</li>           
            </ul>
          </li>           
          <li>
            {{ $t("text.main.privacy_sub_title_043") }}
            <ul class="sub-item">
              <li>{{ $t("text.main.privacy_sub_title_0431") }}</li>           
            </ul>
          </li>          
          <li>
            {{ $t("text.main.privacy_sub_title_044") }}
            <ul class="sub-item">
              <li>{{ $t("text.main.privacy_sub_title_0441") }}</li>
              <li>{{ $t("text.main.privacy_sub_title_0442") }}</li>
              <li>{{ $t("text.main.privacy_sub_title_0443") }}</li>
              <li>{{ $t("text.main.privacy_sub_title_0444") }}</li>              
            </ul>
          </li>
        </ul>

        <h5>{{ $t("text.main.privacy_title_05") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_051") }}
          </li>

          <li>
            {{ $t("text.main.privacy_sub_title_052") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_053") }}
          </li>         
        </ul>                   

        <h5>{{ $t("text.main.privacy_title_06") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_061") }}
          </li>
        </ul>  

        <h5>{{ $t("text.main.privacy_title_07") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_071") }}
          </li>
        </ul>  

        <h5>{{ $t("text.main.privacy_title_08") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_081") }}
          </li>
        </ul>  

        <h5>{{ $t("text.main.privacy_title_09") }}</h5>
        <ul>
          <li>
            {{ $t("text.main.privacy_sub_title_091") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_092") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_093") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_094") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_095") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_096") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_097") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_098") }}
          </li>
          <li>
            {{ $t("text.main.privacy_sub_title_099") }}
          </li>
        </ul>  
        
      </div><!-- // rule-list -->
    </div>
  </div>
</template>

<script>
import location from '@/components/Location'

export default {
  name: 'Policy',
  components: {
    location
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>